const categoryCheckboxes = document.querySelectorAll('.designGallery__checkbox--single');
const categoryCheckboxesAll = document.querySelector('.designGallery__checkbox--all');
const slides = () => document.querySelectorAll('.swiper-slide');

let activeCategories = [];
let swiper;

function toggleSlides() {
    slides().forEach(slide => {
        if (!activeCategories.includes(slide.getAttribute('data-slide-category'))) {
            slide.classList.add('hidden');
        } else {
            slide.classList.remove('hidden');
        }
    });

    swiper.update();
}

function initMenuToggle() {
        const menuToggle = document.querySelector('#menuToggle');
        const galleryMenu = document.querySelector('#galleryMenu');

        menuToggle.addEventListener('click', () => {
            galleryMenu.classList.toggle('active');
        });

        const menuClose = document.querySelector('.swiper-wrapper');
        menuClose.addEventListener('click', () => {
            galleryMenu.classList.contains('active') && galleryMenu.classList.remove('active');
        });
      
}

function handleSingleInput(chk) {
   if (activeCategories.length === 1 && chk.value !== activeCategories[0]) {
        chk.checked = true;
        activeCategories.push(chk.value);
    } else if(activeCategories.length === 1 && chk.value === activeCategories[0]) {
        chk.checked = true;
    } else {
        if (chk.checked) {
            activeCategories.push(chk.value);
        } else {
            const i = activeCategories.indexOf(chk.value);
            activeCategories.splice(i, 1);
        }
    }

    toggleSlides();

    slides().forEach(slide => {
        slide.classList.contains('hidden');
        categoryCheckboxesAll.checked = false;
    });
}

function handleInputAll() {
    if (categoryCheckboxesAll.checked) {
        categoryCheckboxes.forEach(chk => {
            if (!chk.checked) {
                chk.checked = true;
                activeCategories.push(chk.value);
            }
        })
    } else {
        categoryCheckboxes.forEach(chk => chk.checked = false);
        activeCategories = [];

        const defaultCheckbox = document.querySelector('.designGallery__checkbox--default');
        defaultCheckbox.checked = true;
        activeCategories.push(defaultCheckbox.value);
    }

    toggleSlides();
}

function initDesignGallery() {
    initMenuToggle();

    swiper = new Swiper(".designSwiper", {
        slidesPerView: 1,
        effect: "fade",
        loop: true,
        autoResize: true,
        observer: true,
        //   autoWidth: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
  
    categoryCheckboxes.forEach(chk => {
        chk.checked && activeCategories.push(chk.value);
    });

    categoryCheckboxesAll.addEventListener('click', handleInputAll);

    categoryCheckboxes.forEach(chk => chk.addEventListener('click', () => {
        if (chk.value == 'all') {
            if (chk.checked) {
                handleInputAll();
            } else {
                handleSingleInput(chk);
            }
        } else {
            handleSingleInput(chk);
        }
    }));

    toggleSlides();
}


export default initDesignGallery;


