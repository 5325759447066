function toggleDesignContent() {
    const designContentBtns = document.querySelectorAll('.hero__design--option');

designContentBtns.forEach(btn => {
    btn.addEventListener('click', () => {
        const heroBg = document.querySelector(`.hero__gallery`);
        const selectedKey = btn.getAttribute('data-toggle');

        const currentHeroTitle = document.querySelector(`.hero__title.active`);
        const selectedHeroTitle = document.querySelector(`.hero__title[data-hero='${selectedKey}']`);

        const currentBtn = document.querySelector('.hero__design--option.active');
        const currentContent = document.querySelector('.designContent.active');
        const currentContentKey = currentContent.getAttribute('data-subpage');

        const selectedBtn = document.querySelector(`.hero__design--option[data-toggle='${selectedKey}']`);
        const selectedContent = document.querySelector(`.designContent[data-subpage='${selectedKey}']`);

        if (selectedKey !== currentContentKey) {
            currentHeroTitle.classList.remove('active');
            currentBtn.classList.remove('active');
            currentContent.classList.remove('active');

            selectedHeroTitle.classList.add('active');
            selectedBtn.classList.add('active');
            selectedContent.classList.add('active');

            heroBg.setAttribute('data-hero', selectedKey)
        }
    })
})
}


export default toggleDesignContent;