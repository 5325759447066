import 'regenerator-runtime/runtime';

const url = 'https://finelineapps.com/wildridge/php/contact_me.php';

const currentPage = document.querySelector('.content__main').getAttribute('data-page');
const formInputs = document.querySelectorAll('.form-input');
// const submitBtn = document.querySelector('#submitForm');
const response = document.querySelector('#response');
const contactBtns = document.querySelectorAll('.btnContact');

    if (currentPage == 'index' || currentPage == 'team') {
        // submitBtn.textContent = 'Contact Us';
        contactBtns.forEach(btn => btn.textContent = 'Contact Us');
    } else {
        contactBtns.forEach(btn => btn.textContent = 'Book a Consultation');
    }

let error = false;

async function postForm(data) {
    $.post(url, data, function (data) {
        response.textContent = 'Thank you! We will be in touch shortly.'
            response.classList.add('success', 'active');

            setTimeout(() => {
                response.textContent = ''
                response.classList.remove('success', 'active');

                formInputs.forEach(input => input.value = '');
                MicroModal.close('contactPopup');
            }, 3000);
    }).fail(err => {
            console.log(err);
            response.textContent = 'Something went wrong... please email sales@wildridge.com'
            response.classList.add('error', 'active');    
        })

}

function handleFormSubmit() {
    // if (submitBtn) {
    //     submitBtn.addEventListener('click', () => {
    //         let subject;
    
    //         switch (currentPage) {
    //             case 'design':
    //                 subject = 'You have recieved a new consultation request from the Design & Construction page';
    //                 break;
            
    //             case 'maintenance':
    //                 subject = 'You have recieved a new consultation request from the Maintenance page';
    //                 break;
            
    //             default:
    //                 subject = 'You have received a new general inquiry from the home page';
    //                 break;
    //         }
    
    //         const formData = {
    //             subject: subject,
    //             firstName: document.querySelector(".form-input[name='firstName']").value,
    //             lastName: document.querySelector(".form-input[name='lastName']").value,
    //             email: document.querySelector(".form-input[name='email']").value,
    //             phoneNumber: document.querySelector(".form-input[name='phoneNumber']").value,
    //             postalCode: document.querySelector("input[name='postalCode']").value,
    //             budget: document.querySelector(".form-input[name='budget']").value,
    //             maintenanceService: document.querySelector(".form-input[name='maintenance-service']").value,
    //             service: document.querySelector(".form-input[name='service']").value,
    //             message: document.querySelector(".form-input[name='message']").value,
    //         }
    
    //         if (formData.firstName === '' || formData.lastName === ''  || formData.email === '') {
    //             error = true;
    //         }
    
    //         if (error == true) {
    //             response.textContent = 'Please fill out all required fields!';
    //             response.classList.add('error', 'active');
    //         } else {
    //             postForm(formData);
    //             gtag('event', 'Submit_Form');
    //         }
    //     });
    // }

    // formInputs.forEach(input => {
    //     input.addEventListener('input', () => {
    //         error = false;
    //         response.classList.contains('active') && response.classList.remove('error', 'active')
    //     });
    // })
}

export default handleFormSubmit;