import './styles/main.scss';

import initDesignGallery from './modules/designGallery';
import handleFormSubmit from './modules/handleFormSubmit';
import toggleDesignContent from './modules/toggleDesignContent';

const body = document.querySelector('body');
const navToggle = document.querySelector('#navToggle');
const navContent = document.querySelector('.nav__content');

navToggle.addEventListener('click', () => {
    body.classList.toggle('noscroll');
    navToggle.classList.toggle('is-active');
    navContent.classList.toggle('active');
})

handleFormSubmit();

const currentPage = document.querySelector('.content__main').getAttribute('data-page');

if (currentPage != null) {
    const navActive = document.querySelector(`.nav__link[data-page='${currentPage}']`);
    navActive.classList.add('active');
}

// Design and Construction
if (currentPage === 'design') {
    toggleDesignContent();
    initDesignGallery();

    const designHero = document.querySelector(`.hero[data-hero='design']`);

    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            designHero.classList.add('heroShrink');
        } else {
            designHero.classList.remove('heroShrink');
        }
    });
}

const btnCareers = document.querySelectorAll('.btnCareers');
btnCareers.forEach(btn => btn.addEventListener('click', () => gtag('event', 'Visit_Bamboo_HR')));